/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 @import url('http://fonts.cdnfonts.com/css/rubik');

 html {
    margin: 0;
    overflow-x: hidden !important;
  }

  body {
    background: #FFFFFF;
    margin: 0;
    overflow: hidden !important;
  }

 .container {
   /* position: fixed; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  /* background: radial-gradient(circle, #039BE5, #01579b); */
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-family: Heebo !important;
}

.loginDivider {
  border: 1px solid #F2F2F2;
  width: 92%;
  margin: 10px;
}
.homeIcon {
  background-image: url('https://storage.googleapis.com/hoome-imgs/app-assets/login/v1/images/home_icon_new.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 150px;
  width: 100%;
  margin-top: 20px;
}
.signedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  height: 29px;
  width: 137px;
  font-family: 'Amaranth', sans-serif;
  font-size: 200%;
  /* background-color: #000000; */
  margin-top: 30px;
  margin-bottom: 14px;
  background-image: url('https://storage.googleapis.com/hoome-imgs/app-assets/login/v1/images/appIcon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 768px) {
  .logo {
    width: 403px;
    height: 86.65px;
  }
}
.logoIcon {
  top: 4px;
  font-size: 32px;
  margin-right: -2px;
  position: relative;
}
.caption {
  margin: 10px 0 10px 0;
  font-family: Heebo;
  font-size: 22px;
  line-height: 32px;
  color: #000000
}
.skip {
  font-weight:lighter;
  color:white;
  opacity: 0.7;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.skip:HOVER {
  text-decoration: underline;
}
.firebaseUi {
  width: 100%;
}

.button {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
}

.isBold {
  font-weight: 700;
}
