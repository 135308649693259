/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 .firebaseui-container {
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  min-height: 150px;
  border-radius: 20px;
  box-shadow: none;
  direction: rtl;
  background: transparent !important;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-sign-in {
  background: transparent;
}

.firebaseui-container.firebaseui-id-page-callback {
  background: transparent;
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-card-header {
  display: none;
}
.firebaseui-subtitle, .firebaseui-text {
  color: #000000;
  font-size: 0px !important;
}
.firebaseui-subtitle, .firebaseui-text::after {
  content: "כבר התחברת עם חשבון זה דרך גוגל בעבר";
  font-size: 16px;
  color: #000000;
}

.firebaseui-id-page-password-recovery .firebaseui-text::after {
  content: "איפוס סיסמא";
  font-size: 16px;
  color: #000000;
}

.firebaseui-id-page-password-recovery-email-sent .firebaseui-text::after {
  content: "הוראות לשיחזור הסיסמא נשלחו לך לאימייל";
  font-size: 16px;
  color: #000000;
}

.firebaseui-id-page-password-recovery-email-sent .firebaseui-id-submit {
  content: "סיום";
  background-color: #fff !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 16px;
}

.firebaseui-id-page-password-recovery-email-sent .firebaseui-id-submit::after {
  content: "סיום";
  background-color: #fff !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 16px;
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: white;
  border-radius: 15px;
  font-size: 0 !important;
}
.firebaseui-id-page-password-sign-up .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button::after {
  font-size: 14px;
  content: "שמור";
  color: black;
}
.firebaseui-id-page-federated-linking .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background-color: white !important;
  background: #FFFFFF !important;
}
.firebaseui-id-page-federated-linking .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button::after {
  font-size: 14px;
  content: "התחבר עם גוגל";
  color: black;
  background-color: white !important;
  background: #FFFFFF !important;
}
.firebaseui-id-page-sign-in .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button::after {
  font-size: 14px;
  content: "הבא";
  color: black;
}
/* .firebaseui-id-page-sign-in .firebaseui-textfield.is-upgraded .mdl-textfield__label.firebaseui-label::before {
  content: "אימייל";
  font-size: 14px;
  color: black;
} */
.firebaseui-form-actions .mdl-button--primary.firebaseui-button {
  background: white !important;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  border-radius: 15px;
  font-size: 0 !important;
}

.firebaseui-form-actions .mdl-button--primary.firebaseui-button::after {
  font-size: 14px;
  content: "בטל";
  color: black;
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-card-content {
  padding: 0 !important;
  color: #fff !important;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}
.firebaseui-idp-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.firebaseui-idp-button {
  display: flex !important;
  direction: rtl !important;
  justify-content: start !important;
  max-width: 300px !important;
  width: 300px !important;
  margin-right: 17px;
  text-align: center !important;
  border-radius: 15px !important;
  background-color: #fff !important;
  background: #fff !important;
  border: 1px solid #d0d8d8 !important;
  box-shadow: none !important;
  padding-left: 0 !important;

}
.firebaseui-idp-text {
  color: #4F4F4F !important;
  font-family: Heebo;
  font-size: 0px !important;
  margin-right: 12px;
  padding-left: 0 !important;

}

.firebaseui-list-item {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: flex;
  align-self: center;
}
.firebaseui-idp-google .firebaseui-idp-text-long::after {
  font-size: 14px;
  content: "כניסה עם גוגל";
  color: #4BB5AF;
}

.firebaseui-idp-facebook .firebaseui-idp-text-long::after {
  font-size: 14px;
  content: "כניסה עם פייסבוק";
  color: #4BB5AF;
}

/*.firebaseui-idp-facebook .firebaseui-idp-icon1{*/
/*  display: none !important;*/
/*}*/

/*.firebaseui-idp-facebook .firebaseui-idp-icon-wrapper1 {*/
/*  height: 18px;*/
/*  width: 18px;*/
/*  background-image: url('../assets/images/interface.svg');*/
/*  background-repeat: no-repeat;*/
/*}*/

.firebaseui-idp-password .firebaseui-idp-text-long::after {
  font-size: 14px;
  content: "כניסה עם מייל";
  color: #4BB5AF;
}



.firebaseui-idp-password .firebaseui-idp-icon{
  display: none !important;
}
.firebaseui-idp-icon-wrapper {
  display: flex;
  align-self: center;
}

.firebaseui-idp-button .firebaseui-idp-icon-wrapper {
  margin-left: 56px !important;
}

.firebaseui-idp-password .firebaseui-idp-icon-wrapper {
  height: 18px;
  width: 18px;
  background-image: url('https://storage.googleapis.com/hoome-imgs/app-assets/login/v1/images/interface.svg');
  background-repeat: no-repeat;
}

.firebaseui-container.mdl-card {
  box-shadow: none;
  min-height: auto;
}

.firebaseui-input {
  direction: rtl !important;
  text-align: right !important;
  color: #000000 !important;
}

.firebaseui-input, .firebaseui-input-invalid {
  color: #000000 !important;
  direction: rtl !important;
  text-align: right !important;
}

.firebaseui-input-invalid {
  border-color: #ff5e5e !important;
}

.firebaseui-error {
  color: #ff5e5e !important;
  text-align: right !important;
}

/* .firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: #fff !important;
} */

.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
  background-color: #4BB5AF !important;
  border-color: #4BB5AF !important;
}

.firebaseui-textfield .mdl-textfield__label.firebaseui-label {
  font-size: 0px !important;
  direction: rtl !important;
  text-align: right !important;
}
label[for="ui-sign-in-email-input"]::before {
  content: "אימייל";
  font-size: 14px;
  color: #000000;
}
label[for="ui-sign-in-name-input"]::before {
  content: "שם תצוגה";
  font-size: 14px;
  color: #000000;
}
label[for="ui-sign-in-password-input"]::before {
  content: "סיסמא לאתר לוקי";
  font-size: 14px;
  color: #000000;
}
label[for="ui-sign-in-new-password-input"]::before {
  content: "סיסמא לאתר לוקי";
  font-size: 14px;
  color: #000000;
}

.firebaseui-subtitle {
  /* font-size: 0px !important; */
  text-align: right !important;
  color: #000000 !important;
}

.firebaseui-text {
  /* font-size: 0px !important; */
  text-align: right !important;
  color: #000000 !important;
}

.firebaseui-card-actions {
  padding-right: 0 !important;
}

.firebaseui-subtitle::after {
  font-size: 20px;
  content: "קיים חשבון פעיל";
}
.firebaseui-id-page-password-sign-in .mdl-button::after {
  font-size: 14px;
  content: "התחבר";
  color: black;
}
.firebaseui-input-floating-button {
  left: 0px !important;
}
.firebaseui-link.firebaseui-id-secondary-link {
  font-size: 0px;

}
.firebaseui-link.firebaseui-id-secondary-link::after {
  font-size: 14px;
  color: #000000;
  content: "?שכחת סיסמא";
}
.firebaseui-id-submit::after{
  font-size: 14px;
  color: black;
  content: "שלח";
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-email-error {
  font-size: 0px;
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-email-error::after {
  font-size: 14px;
  content: "הכנס/י את האימייל שלך כדי להמשיך";
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-name-error {
  font-size: 0px;
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-name-error::after {
  font-size: 14px;
  content: "יש להכניס שם המשתמש";
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-new-password-error::after {
  color: #ff5e5e !important;
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-name-error {
  font-size: 0px;
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-name-error::after {
  font-size: 14px;
  content: "יש להכניס שם המשתמש";
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-new-password-error {
  font-size: 0px;
}

p.firebaseui-error.firebaseui-text-input-error.firebaseui-id-new-password-error::after {
  font-size: 14px;
  content: "יש להכניס סיסמא";
  color: #000000;
}

